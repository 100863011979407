// Import our custom CSS
import '../scss/styles.scss'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'




'use strict';

/**
 * ページ処理：新規登録ページ
 */
function funcNewAccount() {
	// バリデーション設定（送信時）
	const form = document.getElementById('topForm');
	const mdlConfirm = document.getElementById('modalConfirm1');

	form.addEventListener('submit', (e) => {
		validate_email_confirm(); // メールアドレスバリデーション実行
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();
		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
	form.addEventListener("change", validate_email_confirm);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * ページ処理：Officialページ
 */
function funcOfficial() {
	// バリデーション設定（送信時）
	const form = document.getElementById('officialForm');
	const mdlConfirm = document.getElementById('modalConfirm1');

	form.addEventListener('submit', (e) => {
		validate_passwd_confirm(); // パスワードバリデーション実行
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();
		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
	form.addEventListener("change", validate_passwd_confirm);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * ページ処理：Loginページ
 */
function funcLogin() {
	// バリデーション設定（送信時）
	const form = document.getElementById('frmLogin');
	form.addEventListener('submit', (e) => {
//		validate_login(); // 各入力項目にバリデーション実行
		if (!form.checkValidity()) {
			event.preventDefault();
			event.stopPropagation();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
//	form.addEventListener("change", validate_login);
}

/**
 * ページ処理：ChgInitPasswdページ
 */
function funcChgInitPasswd() {
	// バリデーション設定（送信時）
	const form = document.getElementById('frmChgInitPasswd');
	const mdlConfirm = document.getElementById('modalConfirm1');

	form.addEventListener('submit', (e) => {
		validate_passwd_confirm(); // パスワードバリデーション実行
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();
		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
	form.addEventListener("change", validate_passwd_confirm);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * ページ処理：EditPasswdページ
 */
function funcEditPasswd() {
	// バリデーション設定（送信時）
	const form = document.getElementById('frmEditPasswd');
	const mdlConfirm = document.getElementById('modalConfirm1');

	// フォーム送信
	form.addEventListener('submit', (e) => {
		validate_passwd_confirm(); // パスワードバリデーション実行
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();
		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}

		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
	form.addEventListener("change", validate_passwd_confirm);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * ページ処理：Officialページ
 */
function funcEditAccount() {
	// バリデーション設定（送信時）
	const form = document.getElementById('frmEditAccount');
	const mdlConfirm = document.getElementById('modalConfirm1');

	form.addEventListener('submit', (e) => {
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();

		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * ページ処理：メールアドレス変更ページ
 */
function funcChgEmail() {
	// バリデーション設定（送信時）
	const form = document.getElementById('frmChgEmail');
	const mdlConfirm = document.getElementById('modalConfirm1');

	form.addEventListener('submit', (e) => {
		validate_email_confirm(); // メールアドレスバリデーション実行
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();

		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
	form.addEventListener("change", validate_email_confirm);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * ページ処理：共通バリデーション処理
 */
function funcCommValid(form_id) {
	// バリデーション設定（送信時）
	const form = document.getElementsByTagName('form').item(0);

	form.addEventListener('submit', (e) => {
		if (!form.checkValidity()) {
			event.preventDefault(); // イベント伝搬キャンセル
			event.stopPropagation();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);
}

/**
 * ページ処理：パスワード忘れページ
 */
function funcForgotPasswd() {
	// バリデーション設定（送信時）
	const form = document.getElementById('frmForgotPasswd');
	const mdlConfirm = document.getElementById('modalConfirm1');

	form.addEventListener('submit', (e) => {
		validate_email_confirm(); // メールアドレスバリデーション実行
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();

		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
	form.addEventListener("change", validate_email_confirm);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * ページ処理：パスワード再設定ページ
 */
function funcInitPasswd() {
	// バリデーション設定（送信時）
	const form = document.getElementById('frmInitPasswd');
	const mdlConfirm = document.getElementById('modalConfirm1');

	form.addEventListener('submit', (e) => {
		validate_passwd_confirm(); // パスワードバリデーション実行
		event.preventDefault(); // イベント伝搬キャンセル
		event.stopPropagation();

		if (form.checkValidity()) {
			// 確認モーダル
			const myModal = new bootstrap.Modal(mdlConfirm);
			myModal.show();
		}
		form.classList.add('was-validated');
	  },
	  { passive: false }
	);

	// バリデーション設定（変更時）
	form.addEventListener("change", validate_passwd_confirm);

	// 確認モーダルOK
	const btnModalOk = document.getElementById('btnModalOk');
	btnModalOk.addEventListener("click", () => {
		form.submit();
	});
}

/**
 * メールアドレスと再入力のバリデーション処理
 */
const validate_email_confirm = () => {
	// 再入力テキストボックスのバリデーション
	const email = document.getElementById('email');
	const confirm_email = document.getElementById('confirm_email');
	const confirm_email_err = document.getElementById('confirm_email_err');

	// HTMLバリデーションのエラーメッセージを設定する。（空の時は成功）
	let err_msg = '';
	if(confirm_email.value=='') {
		err_msg = confirm_email_err.dataset.errmsg_required;
	}
	else if(email.value !== confirm_email.value) {
		err_msg = confirm_email_err.dataset.errmsg_nomatch;
	}
	confirm_email.setCustomValidity( err_msg );
	confirm_email_err.textContent = err_msg
};

/**
 * パスワードと再入力のバリデーション処理
 */
const validate_passwd_confirm = () => {
	// 再入力テキストボックスのバリデーション
	const passwd = document.getElementById('password');
	const passwd_confirm = document.getElementById('passwd_confirm');
	const passwd_confirm_err = document.getElementById('passwd_confirm_err');

	// HTMLバリデーションのエラーメッセージを設定する。（空の時は成功）
	let err_msg = '';
	if(passwd_confirm.value=='') {
		err_msg = passwd_confirm_err.dataset.errmsg_required;
	}
	else if(passwd.value !== passwd_confirm.value) {
		err_msg = passwd_confirm_err.dataset.errmsg_nomatch;
	}
	passwd_confirm.setCustomValidity( err_msg );
	passwd_confirm_err.textContent = err_msg
};

/**
 * ChgInitPasswdページのバリデーション処理
 */
/*
const validate_chg_init_passwd = () => {
	// 再入力テキストボックスのバリデーション
	const passwd = document.getElementById('password');
	const passwd_confirm = document.getElementById('passwd_confirm');
	const passwd_confirm_err = document.getElementById('passwd_confirm_err');

	// HTMLバリデーションのエラーメッセージを設定する。（空の時は成功）
	let err_msg = '';
	if(passwd_confirm.value=='') {
		err_msg = passwd_confirm_err.dataset.errmsg_required;
	}
	else if(passwd.value !== passwd_confirm.value) {
		err_msg = passwd_confirm_err.dataset.errmsg_nomatch;
	}
	passwd_confirm.setCustomValidity( err_msg );
	passwd_confirm_err.textContent = err_msg
};
*/

/**
 * 各ページに対応する処理関数テーブル
 */
const page_func = [
	{id : 'new_account', func_name : funcNewAccount},
	{id : 'official', func_name : funcOfficial},
	{id : 'login', func_name : funcLogin},
	{id : 'chg_init_passwd', func_name : funcChgInitPasswd},
	{id : 'edit_passwd', func_name : funcEditPasswd},
	{id : 'edit_account', func_name : funcEditAccount},
	{id : 'chg_email', func_name : funcChgEmail},
	{id : 'chg_email_r', func_name : funcCommValid},
	{id : 'forgot_passwd', func_name : funcForgotPasswd},
	{id : 'init_passwd', func_name : funcInitPasswd}
];

(() => {
	const page_id = document.querySelector('body').id;
	const func = page_func.find( p => p.id == page_id );
	if(func != undefined ) {
		func.func_name();
	}
})();


